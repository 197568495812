import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsService } from './core/services/analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

    constructor(private primengConfig: PrimeNGConfig, private renderer: Renderer2, private router: Router, private activatedRoute: ActivatedRoute, private analytics: AnalyticsService) { }

    ngOnInit() {
        // PrimeNG Ripple Effect
        this.primengConfig.ripple = true;
        // Google Analytics
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            const hostname = window.location.hostname; // Get current hostname
            const glParams = this.activatedRoute.snapshot.queryParams['_gl'] || ''; // Get cross-domain parameters
            const fullPagePath = `${hostname}${event.urlAfterRedirects}`; // Construct full page path
    
            this.analytics.trackPageView({
                productName: this.getProductNameFromUrl(event.urlAfterRedirects),
                path: fullPagePath,
                hostname: hostname,
                glParams: glParams
            });
          }
        });
    }

    private getProductNameFromUrl(url: string): string {
        // Map URLs to product names
        const productMap: { [key: string]: string } = {
            '/legislation-monitor': 'Legislation Monitor',
            '/customer-insight': 'Customer Insight',
            '/competitive-edge': 'Competitive Edge',
            '/supplier-iq': 'Supplier IQ',
            '/': 'Product Demo Catalog'
        };
        
        return productMap[url] || 'Unknown Product';
    }

    ngAfterViewInit(): void {

    }
}    
