import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivationServiceDemoService } from '../core/services/activation-service-demo.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subscription } from 'rxjs';
import { TokenService } from '../core/services/token.service';
import { AnalyticsService } from '../core/services/analytics.service';
@Component({
  selector: 'app-activation-service-demo',
  templateUrl: './activation-service-demo.component.html',
  styleUrls: ['./activation-service-demo.component.scss'],
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule]
})
export class ActivationServiceDemoComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();
    public isActivating = true;
    public activationError = false;
    public step = 0;
    public showWelcome = true;
    private token: string = '';
    public stepError: number | null = null;
    public countdown: number = 5;
    public currentErrorMessage: string[] = [];
    public redirectCountdown: number = 5;

    public steps = [
        {
            label: 'Validating Credentials',
            errorMessage: 'Invalid token provided'
        },
        {
            label: 'Configuring Service',
            errorMessage: 'Service configuration failed'
        },
        {
            label: 'Completing Setup',
            errorMessage: 'Setup completion failed'
        }
    ];

    public excitementMessages = [
        "Preparing your personalized experience...",
        "Getting everything ready...",
        "Almost there...",
        "Your insights await...",
        "Launching in moments...",
    ];

    public currentMessage: string = this.excitementMessages[0];

    getStepIcon(stepNumber: number): string {
        if (stepNumber === 0) {
            if (this.stepError === 0) return 'pi-times';
            if (this.step > 0) return 'pi-check';
            if (this.step === 0 && this.isActivating) return 'pi-spinner pi-spin';
            return 'pi-circle-fill';
        }
        
        if (stepNumber === 1) {
            if (this.stepError === 1) return 'pi-times';
            if (this.step > 1) return 'pi-check';
            if (this.step === 1) return 'pi-spinner pi-spin';
            return 'pi-circle-fill';
        }
        
        if (stepNumber === 2) {
            if (this.step === 3) return 'pi-check';
            return 'pi-circle-fill';
        }
        
        return 'pi-circle-fill';
    }

    getCircleOffset(): number {
        const totalLength = 283; // Circumference of the circle
        const maxCount = 5; // Starting countdown value
        return totalLength * (1 - (this.countdown / maxCount));
    }

    getProgressPercentage(): number {
        const maxRedirectTime = 5; // Changed from 10 to 5 seconds
        return ((maxRedirectTime - this.redirectCountdown) / maxRedirectTime) * 100;
    }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activationService: ActivationServiceDemoService,
    private tokenService: TokenService,
    private analyticsService: AnalyticsService
  ) {
    this.subscription.add(this.route.params.subscribe(params => {
        this.token = params['token'];
        
        this.analyticsService.trackAction('demo_activation_start', {
            productName: 'Services Demo Activation',
            category: 'Demo Activation',
            label: 'Activation Started',
            step: 'activation_initiated'
        });
        
        this.validateAndActivate();
    }));
  }

  ngOnInit() {

  }

  private validateAndActivate() {
    // Clear existing token first
    this.tokenService.removeToken();
    
    this.resetState();
    
    const countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
            clearInterval(countdownInterval);
        }
    }, 1000);
    
    if (!this.token || !this.isValidToken(this.token)) {
        setTimeout(() => {
            this.showWelcome = false;
            this.step = 0;
            this.isActivating = false;
            this.activationError = true;
            this.stepError = 0;
            this.currentErrorMessage = ['Token is invalid or missing. Please check your activation link.'];
            
            this.analyticsService.trackAction('demo_activation_error', {
                productName: 'Services Demo Activation',
                category: 'Demo Activation',
                label: 'Invalid Token',
                step: 'token_validation_failed'
            });
        }, 5000);
        return;
    }
    
    setTimeout(() => {
        this.showWelcome = false;
        this.step = 1;
        
        this.analyticsService.trackAction('demo_activation_progress', {
            productName: 'Services Demo Activation',
            category: 'Demo Activation',
            label: 'Token Validated',
            step: 'token_validated'
        });
        
        this.processActivation();
    }, 5000);
  }

  private processActivation() {
    this.step = 2;
    
    this.subscription.add(this.activationService.ActivateServiceDemo(this.token).subscribe({
        next: (response: { activate: { status: boolean, err: any, token: string, id?: string }, ipd: string, authorized: boolean }) => {
            if(Object.keys(response.activate.err).length === 0 && response.activate.status) {
                console.log('response', response);
                console.log('storing token', response.activate.token);
                this.tokenService.storeToken(response.activate.token);
                this.step = 3;
                this.isActivating = false;
                
                this.redirectCountdown = 5;
                const redirectInterval = setInterval(() => {
                    this.redirectCountdown--;
                    
                    if (this.redirectCountdown <= 5 && this.redirectCountdown > 4) {
                        this.currentMessage = this.excitementMessages[0];
                    } else if (this.redirectCountdown <= 4 && this.redirectCountdown > 3) {
                        this.currentMessage = this.excitementMessages[1];
                    } else if (this.redirectCountdown <= 3 && this.redirectCountdown > 2) {
                        this.currentMessage = this.excitementMessages[2];
                    } else if (this.redirectCountdown <= 2 && this.redirectCountdown > 1) {
                        this.currentMessage = this.excitementMessages[3];
                    } else {
                        this.currentMessage = this.excitementMessages[4];
                    }

                    if (this.redirectCountdown === 0) {
                        clearInterval(redirectInterval);
                        // Determine redirect route based on ID
                        const id = response.activate.id;
                        let redirectRoute = '/notfound';
                        let productName = '';
                        
                        switch(id) {
                            case '70b82250-d22f-44f4-9cb4-6cb116641670':
                                redirectRoute = '/customer-insight/report';
                                productName = 'Customer Insight';
                                break;
                            case 'f76f9c7a-537d-4b6a-ada3-3cc433a13945':
                                redirectRoute = '/competitive-edge/report';
                                productName = 'Competitive Edge';
                                break;
                            case 'bee46ba3-3b7d-4d1a-86ac-34c7a73fa441':
                                redirectRoute = '/supplier-iq/report';
                                productName = 'Supplier IQ';
                                break;
                        }
                        
                        this.router.navigate([redirectRoute]);

                        // Update analytics call with specific product name
                        this.analyticsService.trackAction('demo_activation_complete', {
                            productName: productName,
                            category: 'Demo Activation',
                            label: 'Activation Complete',
                            step: 'activation_successful'
                        });
                    }
                }, 1000);
            } else {
                this.tokenService.removeToken();
                this.isActivating = false;
                this.activationError = true;
                this.stepError = 1;
                this.currentErrorMessage = Object.keys(response.activate.err).length > 0 
                    ? Object.values(response.activate.err).filter((error): error is string => typeof error === 'string')
                    : ['An error occurred during service configuration'];
                
                this.analyticsService.trackAction('demo_activation_error', {
                    productName: 'Services Demo Activation',
                    category: 'Demo Activation',
                    label: 'Activation Failed',
                    step: 'activation_failed',
                    error: JSON.stringify(response.activate.err)
                });
            }
        },
        error: () => {
            this.tokenService.removeToken();
            this.isActivating = false;
            this.activationError = true;
            this.stepError = 1;
            this.currentErrorMessage = ['Network error occurred during service configuration'];
            
            this.analyticsService.trackAction('demo_activation_error', {
                productName: 'Services Demo Activation',
                category: 'Demo Activation',
                label: 'Network Error',
                step: 'network_error'
            });
        }
    }));
  }

  private resetState() {
    this.isActivating = true;
    this.step = 0;
    this.showWelcome = true;
    this.activationError = false;
    this.stepError = null;
    this.countdown = 5;
    this.redirectCountdown = 5;
    this.currentErrorMessage = [];
    this.currentMessage = this.excitementMessages[0];
  }

  retryActivation() {
    this.stepError = null;
    this.currentErrorMessage = [];
    this.activationError = false;
    this.validateAndActivate();
    
    this.analyticsService.trackAction('demo_activation_retry', {
        productName: 'Services Demo Activation',
        category: 'Demo Activation',
        label: 'Activation Retry',
        step: 'retry_attempted'
    });
  }

  private isValidToken(token: string): boolean {
    const hasLetters = /[a-zA-Z]/.test(token);
    const hasNumbers = /[0-9]/.test(token);
    return token.length > 5 && hasLetters && hasNumbers;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
} 