import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsClickEvent, AnalyticsPageView } from '../interfaces/analytics.interface';

declare const gtag: Function;

// Update the interface (add this near the top of the file)
interface AnalyticsEventData {
  datasource?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private readonly trackingId: string = environment.analytics.googleAnalytics.trackingId;

  trackClick(data: AnalyticsClickEvent) {
    if (!this.trackingId) {
      console.warn('Google Analytics tracking ID is not set');
      return;
    }

    gtag('event', data.action || 'product_click', {
      event_category: data.category || 'Product Engagement',
      event_label: data.label || data.productName,
      product_name: data.productName,
      value: data.value || 1,
      datasource: 'demo',
      send_to: this.trackingId
    });
  }

  trackPageView(data: AnalyticsPageView) {
    if (!this.trackingId) {
      console.warn('Google Analytics tracking ID is not set');
      return;
    }

    // Track page view
    gtag('config', this.trackingId, {
      page_path: data.path,
      page_title: data.title || data.productName,
      page_location: data.hostname ? `https://${data.hostname}${data.path}` : undefined,
      linker_param: data.glParams || '',
      datasource: 'demo',
      send_to: this.trackingId
    });
  }

  trackDemoFlow(data: AnalyticsClickEvent) {
    if (!this.trackingId) {
      console.warn('Google Analytics tracking ID is not set');
      return;
    }

    const eventData: AnalyticsEventData = {
      event_category: data.category || 'Demo Flow',
      event_label: data.label,
      product_name: data.productName,
      datasource: 'demo',
      industry: data.industry,
      step: data.action,
      companies_selected: data.value,
      user_email: data.email,
      user_name: data.firstName ? `${data.firstName} ${data.lastName}` : undefined,
      error: data.error,
      timestamp: new Date().toISOString(),
      send_to: this.trackingId
    };

    gtag('event', data.action || 'demo_interaction', eventData);
  }

  // Update trackAction to use the new trackDemoFlow for demo-related events
  trackAction(action: string, data: AnalyticsClickEvent) {
    if (action.includes('demo_') || action.includes('company_') || action.includes('industry_')) {
      this.trackDemoFlow({ ...data, action });
    } else {
      this.trackClick({ ...data, action });
    }
  }
}
